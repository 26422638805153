@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.App-header {
  color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  font-weight: 700;
}

.create-transaction-button {
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: rgb(22, 111, 67);
  color: white;
  font-size: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;

  &:hover {
    box-shadow: none;
    transform: scale(1.05);
    
  }
}

.button-bar {
  margin: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;

  .button-selector {
    text-align: center;

    button {
      background-color: lighten($color: #282c34, $amount: 70%);
      color: #282c34;
      padding: 6px 20px;
      border: none;
      font-weight: bold;
      margin: 0;
      cursor: pointer;

      &.selected {
        background-color: #282c34;
        color: white;
      }
    }
  }
}

.chart-wrapper {
  max-width: 1200px;
  margin: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 800px;
  width: 100%;
  margin: 2rem auto;

  tr {
    color: #282c34;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    td {
      padding: 6px 4px 2px 4px;

      &.center {
        text-align: center;
      }

      &.right {
        text-align: right;
      }

      &.nowrap {
        white-space: nowrap;
      }

      @media only screen and (max-width: 576px) {
        &.mobilehide {
          display: none;
        }
      }

      .verification {
        width: 14px;
        height: 14px;
        border: none;
        border-radius: 25%;
        padding: 0;
        background-color: rgb(214, 214, 214);
        cursor: pointer;

        &.checked {
          background-color: rgba(0, 203, 86, 1);
        }
      }

      .delete-transaction {
        border: none;
        background: none;
        cursor: pointer;
      }

      .description {
        min-width: 90px;
        min-height: 16px;
        cursor: pointer;
      }
    }

    th {
      padding: 4px 4px;
      text-align: left;
    }
  }
}

.neg {
  color: rgba(219, 42, 62, 1);
}
